import { Component, ElementRef, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { QueryService } from 'src/app/services/query.service';

@Component({
  selector: 'jw-modalscan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class ScanComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef, private qryService: QueryService) {
    this.element = el.nativeElement;
  }
  dropdownhidden = false;

  @Input() scannerEnabled: boolean = false;
  @Output() onScan = new EventEmitter<string>();

  ngOnInit(): void {
    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    /*this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });*/

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);

  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  myFunction() {
    this.dropdownhidden = false;
    //document.getElementById("myDropdown").classList.toggle("show");
  }
  filterFunction() {
    var input, filter, ul, li, a, i;
    input = document.getElementById("xmyInput");
    filter = input.value.toUpperCase();
    var div = document.getElementById("myDropdown");
    a = div.getElementsByTagName("a");
    for (i = 0; i < a.length; i++) {
      var txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  }
  focusFunction() {
    this.dropdownhidden = false;
    //document.getElementById("myDropdown").classList.toggle("show");
  }
  focusOutFunction() {

    //document.getElementById("myDropdown").classList.toggle("show");
  }

  scanSuccessHandler($event: any) {
    this.onScan.emit($event)
  }
}