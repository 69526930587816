import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { QueryService } from 'src/app/services/query.service';
import { PasswordComponent } from './pages/login/password/password.component';
import { WerkfichecontainerComponent } from './pages/werkfichecontainer/werkfichecontainer.component';
import { ArtikelComponent } from './pages/modal/artikel/artikel.component';
import { DiverseComponent } from './pages/modal/diverse/diverse.component';
import { OnderhoudComponent } from './pages/modal/onderhoud/onderhoud.component';

// Scanner
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScanComponent } from './pages/modal/scan/scan.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    PasswordComponent,
    WerkfichecontainerComponent,
    ArtikelComponent,
    DiverseComponent,
    OnderhoudComponent,
    ScanComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,    
    ReactiveFormsModule, 
    HttpClientModule,
    CommonModule,
    ZXingScannerModule
  ],
  providers: [
    QueryService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
