import { Login } from 'src/app/class/Login';
import { ArtikelInfo } from 'src/app/class/ArtikelInfo';

export class ec_Onderhoud {
    Login: Login;
    ArtikelInfo: ArtikelInfo;
    UrenOnderhoud: number;
    DatumOnderhoud: string;

    constructor() {
    }
}