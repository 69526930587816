import { Login } from 'src/app/class/Login';
import { Werkfiche } from 'src/app/class/Werkfiche';
import { WerkficheDetail } from 'src/app/class/WerkficheDetail';

export class ec_Werkfiche {
    Login: Login;
    Werkfiche: Werkfiche;
    WerkficheDetails: WerkficheDetail[];
  
    constructor() {
        this.WerkficheDetails = [];
    }
  }