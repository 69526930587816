export class Login {
  public PersNaam: string;
  public PersNr: string;
  public User: string;
  public Password: string;
  public Guid: string;
  public Succes: boolean;
  public Message: string;

  constructor() {
  }
}