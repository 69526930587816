import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { Artikel } from 'src/app/class/Artikel';
import { ModalService } from 'src/app/services/modal.service';
import { QueryService } from 'src/app/services/query.service';

@Component({
  selector: 'jw-diverse',
  templateUrl: './diverse.component.html',
  styleUrls: ['./diverse.component.css']
})
export class DiverseComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef, private qryService: QueryService) {
    this.element = el.nativeElement;
  }
  dropdownhidden = false;

  get Artikels(): Artikel[] {
    if (this.qryService.artikels == undefined || this.qryService.artikels == [])
      return [];
    else
      return this.qryService.artikels;
  }
 

  set selectedDiverse(input){
    this.qryService.selectedDiverse = input;
  }
  get selectedDiverse() {
    
      return (this.qryService.selectedDiverse);
  } 

  set SelectedAantal(input){
    this.qryService.selectedAantal = +input;
  }
  get SelectedAantal() {
    
      return +(this.qryService.selectedAantal);
  }
  onSelect(Artikel: Artikel){
    this.qryService.selectedArtikel = Artikel;
    //document.getElementById("myDropdown").classList.toggle("show");
    this.dropdownhidden = true;
  }

  trackByName(index: number, artikel: Artikel) : string {
    return artikel.Nr;
  }

  ngOnInit(): void {
    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    /*this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });*/

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  myFunction() {
    this.dropdownhidden = false;
    //document.getElementById("myDropdown").classList.toggle("show");
  }
  filterFunction() {
    var input, filter, ul, li, a, i;
    input = document.getElementById("xmyInput2");
    filter = input.value.toUpperCase();
    var div = document.getElementById("myDropdown2");
    a = div.getElementsByTagName("a");
    for (i = 0; i < a.length; i++) {
      var txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  }
  focusFunction() {
    this.dropdownhidden = false;
    //document.getElementById("myDropdown").classList.toggle("show");
  }
  focusOutFunction() {
    this.dropdownhidden = true;
    //document.getElementById("myDropdown").classList.toggle("show");
  }
}