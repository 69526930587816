import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryService } from 'src/app/services/query.service';
import { Login } from 'src/app/class/Login';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  constructor(private queryService: QueryService) { }

  ngOnInit() {
  }

  @Input()
  Username: string = "";
  @Input()
  SelectedLogin: Login;
  @Output() back: EventEmitter<any> = new EventEmitter();

  Password: string = "";
  onclk(nr: string) {
    this.Password += nr;
  }
  onclr() {
    this.Password = "";
  }
  onback(){
    this.Password = "";
    this.back.emit();
  }

  onlogin(){
    this.queryService.DoLogin(this.Username, this.Password, this.SelectedLogin); 
  }
}
