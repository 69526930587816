import { Login } from 'src/app/class/Login';
import { Artikel } from 'src/app/class/Artikel';
import { Werkfiche } from 'src/app/class/Werkfiche';

export class ec_Add {
  Login: Login;
  Artikel: Artikel;
  Werkfiche: Werkfiche;
  IsDiverse: boolean;
  Aantal: number;
  Diverse: string;
  
  constructor() {
  }
}