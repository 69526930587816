
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Login } from 'src/app/class/Login';
import { ec_Detail } from 'src/app/class/encap/ec_Detail';
import { ArtikelInfo } from 'src/app/class/ArtikelInfo';
import { ec_Werkfiche } from 'src/app/class/encap/ec_Werkfiche';
import { Werkfiche } from 'src/app/class/Werkfiche';
import { WerkficheDetail } from 'src/app/class/WerkficheDetail';
import { ec_ArtikelInfo } from 'src/app/class/encap/ec_ArtikelInfo';
import { Artikel } from 'src/app/class/Artikel';
import { ec_Artikel } from 'src/app/class/encap/ec_Artikel';
import { ec_Add } from 'src/app/class/encap/ec_Add';
import { ec_Onderhoud } from 'src/app/class/encap/ec_Onderhoud';

@Injectable()
export class QueryService {

    constructor(private http: HttpClient) {
    }

    selectedArtikel: Artikel;
    selectedDiverse: string;
    selectedAantal: number = 0;
    onderhoud: number = 0;
    date: string = "";
    SelectedLogin: Login;
    Username: string = "";

    /*private apiURLGetUsers = "http://localhost:56572/api/login/getusers";
    private apiURLGetArtikels = "http://localhost:56572/api/werkfiche/getartikels";
    private apiURLDoLogin = "http://localhost:56572/api/login/dologin";
    private apiURLFindDetail = "http://localhost:56572/api/werkfiche/finddetail";
    private apiURLEditDetail = "http://localhost:56572/api/werkfiche/editdetail";
    private apiURLFindWFDetails = "http://localhost:56572/api/werkfiche/wfdetails";
    private apiURLFindWFDetailsDiverse = "http://localhost:56572/api/werkfiche/wfdetailsdiverse";
    private apiURLAddDetail = "http://localhost:56572/api/werkfiche/wfadddetail";
    private apiURLEditStatus = "http://localhost:56572/api/werkfiche/editstatus";
    private apiURLDoOnderhoud = "http://localhost:56572/api/werkfiche/doonderhoud";*/

    private apiURLGetUsers = "https://werkficheapi.powersolutions.eu/api/login/getusers";
    private apiURLGetArtikels = "https://werkficheapi.powersolutions.eu/api/werkfiche/getartikels";
    private apiURLDoLogin = "https://werkficheapi.powersolutions.eu/api/login/dologin";
    private apiURLFindDetail = "https://werkficheapi.powersolutions.eu/api/werkfiche/finddetail";
    private apiURLEditDetail = "https://werkficheapi.powersolutions.eu/api/werkfiche/editdetail";
    private apiURLFindWFDetails = "https://werkficheapi.powersolutions.eu/api/werkfiche/wfdetails";
    private apiURLFindWFDetailsDiverse = "https://werkficheapi.powersolutions.eu/api/werkfiche/wfdetailsdiverse";
    private apiURLAddDetail = "https://werkficheapi.powersolutions.eu/api/werkfiche/wfadddetail";
    private apiURLEditStatus = "https://werkficheapi.powersolutions.eu/api/werkfiche/editstatus";
    private apiURLDoOnderhoud = "https://werkficheapi.powersolutions.eu/api/werkfiche/doonderhoud";

    login: Login;
    gebruikers = [];
    artikels = [];
    logged: boolean = false;
    artikelinfo: ArtikelInfo;
    currentDetailNr: string = "";

    werkfichedetailsartikels = [];
    werkfichedetailsdiverse = [];

    GetUsers() {
        this.http.get<Login[]>(this.apiURLGetUsers).subscribe(responseData => {
            //var joske = responseData;
            this.AssignOnReturn(responseData);
        });
    }
    GetArtikels() {
        this.http.post<ec_Artikel>(this.apiURLGetArtikels, this.login).subscribe(responseData => {
            //var joske = responseData;            
            this.AssignOnReturnArtikels(responseData.Artikels);
        });
    }
    DoLogin(Username: string, Password: string, SelectedLogin: Login) {
        this.login = new Login();

        this.login.PersNr = SelectedLogin.PersNr;
        this.login.User = Username;
        this.login.Password = Password;

        this.http.post<Login>(this.apiURLDoLogin, this.login).subscribe(responseData => {
            this.login = responseData;
            if (this.login.Guid != "" && this.login.Guid != undefined) {
                this.logged = true;
                this.GetArtikels();
            }
            else
                this.logged = false;
        });
    }
    FindDetail(Detailnr: string) {
        this.currentDetailNr = Detailnr;
        var exd = new ec_Detail();
        exd.Login = this.login;
        exd.Input = Detailnr;
        this.artikelinfo = new ArtikelInfo();
        this.http.post<ArtikelInfo>(this.apiURLFindDetail, exd).subscribe(responseData => {
            this.artikelinfo = responseData;
        });
    }

    EditDetail(artikelinfo: ArtikelInfo) {
        var exd = new ec_ArtikelInfo();
        exd.Login = this.login;
        exd.ArtikelInfo = artikelinfo
        this.http.post<ArtikelInfo>(this.apiURLEditDetail, exd).subscribe(responseData => {
            this.artikelinfo = responseData;
        });
    }

    FindWFDetailsArtikels(oWerkfiche: Werkfiche) {
        var exd = new ec_Werkfiche();
        exd.Login = this.login;
        exd.Werkfiche = oWerkfiche;

        this.http.post<ec_Werkfiche>(this.apiURLFindWFDetails, exd).subscribe(responseData => {

            this.AssignOnReturnWF(responseData.WerkficheDetails);
        });
    }
    FindWFDetailsDiverse(oWerkfiche: Werkfiche) {
        var exd = new ec_Werkfiche();
        exd.Login = this.login;
        exd.Werkfiche = oWerkfiche;

        this.http.post<ec_Werkfiche>(this.apiURLFindWFDetailsDiverse, exd).subscribe(responseData => {
            this.AssignOnReturnWFDiverse(responseData.WerkficheDetails);
        });
    }

    AddDetail(oWerkfiche: Werkfiche, diverse: boolean) {
        this.AddDetailFull(oWerkfiche, diverse);
    }
    AddDetailFull(oWerkfiche: Werkfiche, diverse: boolean) {
        var exd = new ec_Add();
        exd.Login = this.login;
        exd.Werkfiche = oWerkfiche;
        exd.Artikel = this.selectedArtikel;
        exd.Diverse = this.selectedDiverse;
        exd.Aantal = this.selectedAantal;
        exd.IsDiverse = diverse;

        this.http.post<boolean>(this.apiURLAddDetail, exd).subscribe(responseData => {
            if (responseData == true) {
                this.FindWFDetailsArtikels(oWerkfiche);
                this.FindWFDetailsDiverse(oWerkfiche);
            }
        });
    }
    EditStatus(artikelinfo) {
        var exd = new ec_ArtikelInfo();
        exd.Login = this.login;
        exd.ArtikelInfo = artikelinfo
        this.http.post<ArtikelInfo>(this.apiURLEditStatus, exd).subscribe(responseData => {
            //this.artikelinfo = responseData;
        });
    }
    DoOnderhoud(artikelinfo) {
        var exd = new ec_Onderhoud();
        exd.Login = this.login;
        exd.ArtikelInfo = artikelinfo;
        exd.DatumOnderhoud = this.date;
        exd.UrenOnderhoud = +this.onderhoud;
        this.http.post<ArtikelInfo>(this.apiURLDoOnderhoud, exd).subscribe(responseData => {
            this.FindDetail(this.currentDetailNr);
            //this.artikelinfo = responseData;
        });
    }

    AssignOnReturn(Gebruikers: Login[]) {
        if (this.gebruikers == undefined)
            this.gebruikers = [];
        while (this.gebruikers.length > 0) {
            this.gebruikers.pop();
        }
        Gebruikers.forEach(element => {
            this.gebruikers.push(element);

        });
    }
    AssignOnReturnWF(Details: WerkficheDetail[]) {
        if (this.werkfichedetailsartikels == undefined)
            this.werkfichedetailsartikels = [];
        while (this.werkfichedetailsartikels.length > 0) {
            this.werkfichedetailsartikels.pop();
        }
        Details.forEach(element => {
            this.werkfichedetailsartikels.push(element);
        });
    }
    AssignOnReturnWFDiverse(Details: WerkficheDetail[]) {
        if (this.werkfichedetailsdiverse == undefined)
            this.werkfichedetailsdiverse = [];
        while (this.werkfichedetailsdiverse.length > 0) {
            this.werkfichedetailsdiverse.pop();
        }
        Details.forEach(element => {
            this.werkfichedetailsdiverse.push(element);
        });
    }
    AssignOnReturnArtikels(Artikels: Artikel[]) {
        if (this.artikels == undefined)
            this.artikels = [];
        while (this.artikels.length > 0) {
            this.artikels.pop();
        }
        Artikels.forEach(element => {
            this.artikels.push(element);

        });
    }
}