import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QueryService } from 'src/app/services/query.service';
import { ArtikelInfo } from 'src/app/class/ArtikelInfo';
import { Werkfiche } from 'src/app/class/Werkfiche';
import { ModalService } from 'src/app/services/modal.service';
import { Artikel } from 'src/app/class/Artikel';

@Component({
  selector: 'app-werkfichecontainer',
  templateUrl: './werkfichecontainer.component.html',
  styleUrls: ['./werkfichecontainer.component.css']
})
export class WerkfichecontainerComponent implements OnInit {

  constructor(private queryService: QueryService, private modalService: ModalService) { }

  get detailselected(): boolean {
    return (this.ArtikelInfo != undefined && this.ArtikelInfo != null && this.ArtikelInfo.Found);
  }
  get werkficheselected(): boolean {
    return (this.SelectedWerkfiche != undefined && this.SelectedWerkfiche != null && this.SelectedWerkfiche != "");
  }
  detail: string;
  detailknown: string;
  //colorselected: string = "orange";
  SelectedWerkfiche: string = "";
  SelectedWerkficheItem: Werkfiche = undefined;
  scannerEnabled: boolean = false;
  get ArtikelInfo(): ArtikelInfo {
    if (this.queryService.artikelinfo != null)
      return this.queryService.artikelinfo;
    else
      return new ArtikelInfo();
  }



  ngOnInit() {
  }

  onkyddetail(event: any) {
    if (event.keyCode == 13) {
      this.queryService.FindDetail(this.detail);
      this.detailknown = this.detail;
    }

  }
  getClassOrange() {
    if (this.colorselected == 'orange')
      return "btn btn-light btn-lg mybtn borangeselected";

    return "btn btn-light btn-lg mybtn xborange ";
  }
  getClassRed() {
    if (this.colorselected == 'red')
      return "btn btn-light btn-lg mybtn bredselected";

    return "btn btn-light btn-lg mybtn xbred ";
  }
  getClassGreen() {
    if (this.colorselected == 'green')
      return "btn btn-light btn-lg mybtn bgreenselected";

    return "btn btn-light btn-lg mybtn xbgreen ";
  }
  getClassYellow() {
    if (this.colorselected == 'yellow')
      return "btn btn-light btn-lg mybtn byellowselected";

    return "btn btn-light btn-lg mybtn xbyellow ";
  }
  getWerkfiches() {
    return this.ArtikelInfo.Werkfiches;
  }
  getWerkfichesDetailArtikels() {
    return this.queryService.werkfichedetailsartikels;
  }
  getWerkfichesDetailDiverse() {
    return this.queryService.werkfichedetailsdiverse;
  }
  color(kleur: string) {
    switch (kleur) {
      case "orange":
        this.ArtikelInfo.Status = "Awaiting Inspection";
        break;
      case "red":
        this.ArtikelInfo.Status = "Under Repair";
        break;
      case "green":
        this.ArtikelInfo.Status = "Available";
        break;
      case "yellow":
        this.ArtikelInfo.Status = "In Reservation";
        break;
    }
    this.queryService.EditStatus(this.ArtikelInfo);
  }
  get colorselected() {
    switch (this.ArtikelInfo.Status) {
      case "Awaiting Inspection":
        return "orange";
      case "Under Repair":
        return "red";
      case "Available":
        return "green"
      case "In Reservation":
        return "yellow"
      default:
        return "green";
    }
  }
  onselectdetails(wf: Werkfiche) {
    this.SelectedWerkfiche = wf.Nr;
    this.SelectedWerkficheItem = wf;
    this.queryService.FindWFDetailsArtikels(wf);
    this.queryService.FindWFDetailsDiverse(wf);
  }
  openModal(id: string) {
    this.modalService.open(id);
  } closeModal(id: string) {
    this.modalService.close(id);
  }
  closeModalWithSave(id: string) {

    this.queryService.AddDetail(this.SelectedWerkficheItem, false);

    this.queryService.selectedArtikel = undefined;
    this.queryService.selectedAantal = 0;

    this.modalService.close(id);
  }
  closeModalWithSaveDiv(id: string) {

    this.queryService.AddDetail(this.SelectedWerkficheItem, true);

    this.queryService.selectedArtikel = undefined;
    this.queryService.selectedAantal = 0;

    this.modalService.close(id);
  }
  closeModalWithSaveOnderhoud(id: string) {

    this.queryService.DoOnderhoud(this.ArtikelInfo);

    this.queryService.onderhoud = undefined;
    this.queryService.selectedAantal = 0;

    this.modalService.close(id);
  }

  logout() {
    this.queryService.login = undefined;
    this.queryService.logged = false;
    this.queryService.SelectedLogin = undefined;
  }

  onScan($event: any) {
    this.detail = $event;
    this.detailknown = this.detail;
    this.queryService.FindDetail(this.detail);    
    this.scannerEnabled = false;
    this.modalService.close('custom-modal-8');
  }
}
