import { Werkfiche } from 'src/app/class/Werkfiche';

export class ArtikelInfo {
    public Nummer: string;
    public Status: string;
    public Urenstand: number;
    public UrenstandVertrek: number;
    public UrenstandLaatsteOH: number;
    public DatumLaatsteOH: string;
    public Found: boolean;
    public Werkfiches: Werkfiche[];

    constructor() {
        this.Werkfiches = [];
    }
}