import { Component, OnInit } from '@angular/core';
import { QueryService } from 'src/app/services/query.service';
import { ModalService } from 'src/app/services/modal.service';
import { Login } from 'src/app/class/Login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private queryService: QueryService, private modalService:ModalService) {    
   }

  ngOnInit() {
    this.queryService.GetUsers();
  }

  get Gebruikers(){
    return this.queryService.gebruikers;
  }
  
  get Username() {
    return this.queryService.Username;
  }
  set Username(val){
    this.queryService.Username = val;
  }
  get SelectedLogin(){
    return this.queryService.SelectedLogin;
  }
  set SelectedLogin(val){
    this.queryService.SelectedLogin = val;
  }
  get UserSelected(): boolean {
    if (this.Username == undefined || this.Username == "")
      return true;

    return false;
  }
  onClick(username: string, user: Login) {
    this.Username = username;
    this.queryService.SelectedLogin = user;
  }

  onBack(){
    this.Username = "";
  }
  openModal(id: string) {
    this.modalService.open(id);
  } closeModal(id: string) {
    this.modalService.close(id);
  }
}
