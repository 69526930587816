import { Component, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { QueryService } from 'src/app/services/query.service';

@Component({
  selector: 'jw-onderhoud',
  templateUrl: './onderhoud.component.html',
  styleUrls: ['./onderhoud.component.css']
})
export class OnderhoudComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef, private qryService: QueryService) {
    this.element = el.nativeElement;
  }
  dropdownhidden = false;
  error = false;

  first = true;
  set Onderhoud(input: number) {
    this.qryService.onderhoud = input;
  }
  get Onderhoud() {
    if (this.first) {
      if (this.qryService.artikelinfo != null) {
        this.Onderhoud = this.qryService.artikelinfo.Urenstand;
        this.first = false;
      }
      else
        this.Onderhoud = 0;
    }
    return (this.qryService.onderhoud);
  }
  set Date(input: string) {
    this.qryService.date = input;
  }
  get Date() {
    return (this.qryService.date);
  }
  onSelect() {

    //document.getElementById("myDropdown").classList.toggle("show");
    this.dropdownhidden = true;
  }

  ngOnInit(): void {
    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    /*this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });*/

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);

  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  myFunction() {
    this.dropdownhidden = false;
    //document.getElementById("myDropdown").classList.toggle("show");
  }
  focusFunction() {
    this.dropdownhidden = false;
    //document.getElementById("myDropdown").classList.toggle("show");
  }
  focusOutFunction() {
    this.dropdownhidden = true;
    //document.getElementById("myDropdown").classList.toggle("show");
  }
  changeUren() {
    if (this.Onderhoud > this.qryService.artikelinfo.Urenstand + 2 || this.Onderhoud < this.qryService.artikelinfo.Urenstand)
      this.error = true;
    else
      this.error = false;
  }
}